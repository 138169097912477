<script lang="ts" setup>
import { register } from 'swiper/element/bundle'

const props = defineProps({
  classNames: {
    type: String,
    required: false,
    default: '',
  },
  spaceBetween: {
    type: Number,
    required: false,
    default: 40,
  },
  slidesPerView: {
    type: Number,
    required: false,
    default: 1,
  },
  breakpoints: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

register()

function onProgress(e) {
  const [swiper, progress] = e.detail
  // console.log(progress)
}

function onSlideChange(e) {
  // console.log('slide changed')
}

const swiperRef = ref(null)
defineExpose({
  updateSwiper: () => {
    if (swiperRef.value) {
      swiperRef.value.swiper.update()
      console.log('swiper updated', swiperRef.value.swiper)
    }
  },
})
</script>

<template>
  <div :class="classNames">
    <swiper-container
      ref="swiperRef"
      :a11y="true"
      :slides-per-view="props.slidesPerView"
      :space-between="props.spaceBetween"
      :centered-slides="false"
      :pagination="false"
      :edge-swipe-detection="true"
      :breakpoints="props.breakpoints"
      @progress="onProgress"
      @slidechange="onSlideChange"
    >
      <slot />
    </swiper-container>
  </div>
</template>
